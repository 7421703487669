/*
 * @Descripttion: 
 * @version: 
 * @Author: zhaoxiang
 * @Date: 2020-02-28 14:03:54
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 16:31:09
 */
// import moment from 'moment';

// 格式化金额过滤器，将传入的金额分转换为元，并保留小数点后两位
function formatMoney(money) {
  let result = Number(money);
  if (Number.isNaN(result)) {
    return '';
  }
  result = (Math.round(result) / 100).toString();
  let dotIndex = result.indexOf('.');
  if (dotIndex < 0) {
    dotIndex = result.length;
    result += '.';
  }
  while (result.length <= dotIndex + 2) {
    result += '0';
  }
  return result;
}

// 将日期时间字符串转换为日期字符串
function formatDate(datetimeStr, formatStr) {
  if (formatStr) {
    return window.$moment(datetimeStr).format(formatStr);
  }
  return window.$moment(datetimeStr).format('L');
}

// 将日期时间字符串转换为日期字符创
function formatTime(datetimeStr) {
  return window.$moment(datetimeStr).format('HH:mm:ss');
}

export default {
  formatMoney: {
    filter: formatMoney,
    // 是否挂载到全局的$filter上
    isMount$filter: true,
  },
  formatDate: {
    filter: formatDate,
    isMount$filter: true,
  },
  formatTime: {
    filter: formatTime,
  },
};
