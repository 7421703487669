export default [
  {
    // 会员列表
    path: 'memberList',
    name: 'memberList',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
      keepAlive: true
    },
    component: () => import('../views/group-management/member-manage/list/index.vue'),
  },
  {
    // 会员详情
    path: 'memberList/info/:memberId?',
    name: 'memberInfo',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/info/index.vue'),
  },
  {
    // 会员积分明细
    path: 'memberList/info/:memberId/integral',
    name: 'memberIntegralList',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/integral-detail/index.vue'),
  },
  {
    // 已注销会员列表
    path: 'canceledMemberList',
    name: 'canceledMemberList',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
      isCanceled:true, //代表是注销状态的会员列表
    },
    component: () => import('../views/group-management/member-manage/list/index.vue'),

  },
  {
    // 已注销会员详情
    path: 'canceledMemberList/info/:memberId',
    name: 'canceledMemberInfo',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
      isCanceled:true, //代表是注销会员详情
    },
    component: () => import('../views/group-management/member-manage/info/index.vue'),
  },
  {
    // 已注销会员积分间夜明细
    path: 'canceledMemberList/info/:memberId/integral',
    name: 'canceledMemberIntegralList',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
      isCanceled:true, //代表是注销状态的积分间夜
    },
    component: () => import('../views/group-management/member-manage/integral-detail/index.vue'),
  },
  {
    // 会员导入
    path: 'memberImport',
    name: 'memberImport',
    meta: {
      permissionCode: 'cp-group-memberImport',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-import/index.vue'),
  },
  {
    // 会员批量升级
    path: 'memberBatchUpgrade',
    name: 'memberBatchUpgrade',
    meta: {
      permissionCode: 'cp-group-memberBatchUpgrade',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-batch-upgrade/index.vue'),
  },
  {
    // 会员批量延期
    path: 'memberBatchDelay',
    name: 'memberBatchDelay',
    meta: {
      permissionCode: 'cp-group-memberBatchDelay',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-batch-delay/index.vue'),
  },
  {
    // 会员配置等级
    path: 'memberLevel',
    name: 'memberLevel',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/level/index.vue'),
  },
  {
    // 会员等级详情
    path: 'memberLevel/info/:levelId?',
    name: 'memberLevelInfo',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/level-info/index.vue'),
  },
  {
    path: 'memberExchange',
    name: 'memberExchange',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/exChange-info/index.vue'),
  },
  {
    // 会员升降级保级策略列表
    path: 'memberLevel/:levelId/strategy/:experienceType?/:upgradePolicyType?',
    name: 'memberStrategy',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/strategy/index.vue'),
  },
  {
    // 会员升降级保级策略详情
    path: 'memberLevel/:levelId/strategy/info/:strategyType/:strategyId?/:experienceType?/:upgradePolicyType?',
    name: 'memberStrategyInfo',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/strategy/strategyinfo/index.vue'),
  },
  {
    // crs积分项目
    path: 'crsScoreProject',
    name: 'crsScoreProject',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/crs-score-project/index.vue'),
  },
  {
    // crs积分项目编辑
    path: 'crsScoreProjectEdit/:consumeItemCode?',
    name: 'crsScoreProjectEdit',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/crs-score-project/crs-score-project-edit.vue'),
  },
  {
    // 积分累计策略
    path: 'scoreTotalStrategy',
    name: 'scoreTotalStrategy',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/score-total-strategy/index.vue'),
  },
  {
    // 积分累计策略详情
    path: 'scoreTotalStrategyEdit/:pointpolicyId?/:type?/:mode?',
    name: 'scoreTotalStrategyEdit',
    meta: {
      permissionCode: 'cp-group-memberConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/score-total-strategy/score-total-strategy-edit.vue'),
  },
  {
    // 卡券列表
    path: 'couponList',
    name: 'group-couponList',
    meta: {
      permissionCode: 'cp-group-memberCoupon',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-coupon/coupon-list.vue'),
  },
  {
    // 卡券详情
    path: 'couponList/couponAdd',
    name: 'couponAdd',
    meta: {
      permissionCode: 'cp-group-memberCoupon',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-coupon/coupon-add.vue'),
  },
  {
    // 卡券查询
    path: 'couponSearch',
    name: 'couponSearch',
    meta: {
      permissionCode: 'cp-group-memberCoupon',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-coupon/coupon-search.vue'),
  },
  {
    // 卡券使用记录
    path: 'couponUseRecord',
    name: 'couponUseRecord',
    meta: {
      permissionCode: 'cp-group-memberCoupon',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-coupon/coupon-use-record.vue'),
  },
  {
    // 批量发送权益券
    path: 'memberRightCouponBatchSend',
    name: 'memberRightCouponBatchSend',
    meta: {
      permissionCode: 'cp-group-memberCoupon',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-coupon/member-right-coupon-batch-send/index.vue'),
  },
  {
    // 提示信息
    path: 'memberCouponHint',
    name: 'memberCouponHint',
    meta: {
      permissionCode: 'cp-group-couponMemberRightHint',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/coupon-hint/coupon-member-right-hint.vue'),
  },
  {
    // 会员来源
    path: 'memberSource',
    name: 'memberSource',
    meta: {      permissionCode: 'cp-group-memberSourceManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-source/index.vue'),
  },
  {
    // 会员充值使用明细
    path: 'memberRechargeUse/:memberNo?',
    name: 'memberRechargeUse',
    meta: {
      permissionCode: 'cp-group-memberChargeUseDetail',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/recharge-use-detail/index.vue'),
  },
  {
    // 充值包配置
    path: 'recharge',
    name: 'recharePackageConfig',
    meta: {
      permissionCode: 'cp-group-memberChargeUseDetail',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/recharge-package/index.vue'),
  },
  {
    // 提示信息配置
    path: 'promptMessageConfig',
    name: 'promptMessageConfig',
    meta: {
      permissionCode: 'cp-group-memberChargeUseDetail',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/prompt-message-config/index.vue'),
  },
  {
    // 集团客史
    path: 'customerHistory',
    name: 'customerHistory',
    meta: {
      permissionCode: 'cp-group-customerHistory',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-customer-history/customer-history-list/index.vue'),
  },
  {
    // 集团客史-详情
    path: 'customerHistory/info/:profileId',
    name: 'customerHistoryInfo',
    meta: {
      permissionCode: 'cp-group-customerHistory',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-customer-history/customer-history-info/index.vue'),
  },
  {
    // 集团客史-消费记录
    path: 'customerHistory/consumeList/:profileId',
    name: 'consumeList',
    meta: {
      permissionCode: 'cp-group-customerHistory',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-customer-history/consume-list/index.vue'),
  },
  {
    // 会员风控-可疑用户
    path: 'entityRiskList',
    name: 'entityRiskList',
    meta: {
      permissionCode: 'cp-group-memberRiskControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-risk/entity-risk-list.vue'),
  },
  {
    // 会员风控-真实拦截信息列表
    path: 'realInterceptList',
    name: 'realInterceptList',
    meta: {
      permissionCode: 'cp-group-memberRiskControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-risk/intercept-page.vue'),
  },
  {
    // 会员风控-虚拟拦截信息列表
    path: 'virtualInterceptList',
    name: 'virtualInterceptList',
    meta: {
      permissionCode: 'cp-group-memberRiskControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-risk/intercept-page.vue'),
  },
  {
    // 会员风控-会员详情
    path: 'memberRiskDetail',
    name: 'memberRiskDetail',
    meta: {
      permissionCode: 'cp-group-memberRiskControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/member-manage/member-risk/detail.vue'),
  },
];
