export default [
  {
    // 会员权益使用
    path: 'hotelCouponUseRecord',
    name: 'hotelCouponUseRecord',
    meta: {
      permissionCode: 'cp-hotel-memberCouponUse',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/member-right-use/hotel-coupon-use-record.vue'),
  },
];
