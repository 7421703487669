<!--
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:54
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-26 16:44:41
 -->
<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <!-- 加key是为了在多路由复用同一个页面组件时，能够重新渲染 -->
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {},
};
</script>
